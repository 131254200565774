import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  DateField,
  BooleanField
} from 'react-admin'
import ChipArrayField from '../../components/chipArrayField'
import {ShowAction} from "../../components/actions"

const UsersShow = (props) => {
  return (
    <Show {...props} actions={<ShowAction record={props}/>}>
      <SimpleShowLayout>
        <NumberField source="id" sortable={false}/>
        <TextField source="nickname" sortable={false}/>
        <TextField source="email" sortable={false}/>
        <TextField source="type" sortable={false}/>
        <TextField source="membershipConvertKo" sortable={false}/>
        <TextField source="birthRange" sortable={false}/>
        <ChipArrayField {...props} type="musclePartIds" label="관리부위"/>
        <ChipArrayField {...props} type="exerciseIds" label="관심운동"/>
        <DateField source="createdAt" locales='ko' sortable={false}/>
        <BooleanField source="isDeleted"/>
        <DateField source="deletedAt" locales='ko' sortable={false}/>
      </SimpleShowLayout>
    </Show>
  )
}

export default UsersShow
