import * as React from 'react'
import { Admin, Resource, defaultTheme } from 'react-admin'
import {createBrowserHistory} from 'history'
import _dataProvider from './providers/data'
import i18nProvider from './providers/i18n'
import {authProvider} from './providers/authProvider'
import {Layout} from './components'
import {auth, admins, users, videos, categories, tags, pushes, notices, faqs, usersVideos, banners, questions} from './pages'
import { createTheme } from '@material-ui/core/styles'

export const dataProvider = _dataProvider(process.env.REACT_APP_URL)
export const history = createBrowserHistory()
const theme = createTheme({
  ...defaultTheme
})

const App = () => {
  return (
    <Admin
      title={process.env.REACT_APP_TITLE}
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={Layout}
      history={history}
      loginPage={auth}
      i18nProvider={i18nProvider}
      theme={theme}
    >
      {(permissions) => {
        const resources = []
        resources.push(<Resource name="admins" {...admins} />)
        resources.push(<Resource name="users" {...users} />)
        resources.push(<Resource name="videos" {...videos} />)
        resources.push(<Resource name="categories" {...categories} />)
        resources.push(<Resource name="tags" {...tags} />)
        resources.push(<Resource name="pushes" {...pushes} />)
        resources.push(<Resource name="notices" {...notices} />)
        resources.push(<Resource name="faqs" {...faqs} />)
        resources.push(<Resource name="usersVideos" {...usersVideos} />)
        resources.push(<Resource name="banners" {...banners} />)
        resources.push(<Resource name="questions" {...questions} />)
        if (permissions === 'master') {
        }
        return resources
      }}
    </Admin>

  )
}

export default App
