import AnnouncementIcon from '@material-ui/icons/Announcement'
import BannerList from './BannerList'
import BannerShow from './BannerShow'
import BannerEdit from './BannerEdit'
import BannerCreate from './BannerCreate'

const Banners = {
  create: BannerCreate,
  list: BannerList,
  show: BannerShow,
  edit: BannerEdit,
  icon: AnnouncementIcon,
}


export default Banners
