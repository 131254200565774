import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_MANY,
  GET_ONE,
  UPDATE,
} from 'react-admin'
import moment from 'moment-timezone'
moment().tz("Asia/Seoul").format()

export function request(apiUrl, type, params) {
  let url = `${apiUrl}/users`
  let options = {}

  switch (type) {
    case CREATE:
      options.method = 'POST'
      options.data = params.data
      break
    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break
    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case 'GET_ONE_WITH_PHONE':
      url += `/phone`
      options.params = params
      break
    case 'UPDATE_COMMISSION':
      url += `/${params.id}/commissions`
      options.method = 'PUT'
      options.data = params.data
      break
    case UPDATE:
      url += `/${params.id}`
      options.method = 'PUT'
      options.data = params.data
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return {url, ...options}
}

const birthRange = [
  "19세 이하",
  "20 - 24세",
  "25 - 29세",
  "30 - 34세",
  "35 - 39세",
  "40 - 44세",
  "45 - 49세",
  "50세 이상"
]

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case UPDATE:
      ret = params
      break
    case GET_ONE:
      if (data.birthRangeId) data.birthRange = birthRange[data.birthRangeId - 1]
      else data.birthRange = '-'
      data.membershipConvertKo = data.membership === 'general' ? '일반' : '구독'
      data.isPaid = data.membership !== 'general'
      data.isDeleted = !!data.deletedAt
      ret = {data}
      break
    case GET_LIST:
      data.data = data.data.map(item => {
        if (item.birthRangeId) item.birthRange = birthRange[item.birthRangeId - 1]
        else item.birthRange = '-'
        item.membershipConvertKo = item.membership === 'general' ? '일반' : '구독'
        item.type = item.type === 'general' ? '일반' : item.type === 'kakao' ? '카카오' : item.type === 'naver' ? '네이버' : item.type === 'google' ? '구글' : '애플'
        item.isDeleted = !!item.deletedAt
        return item
      })
      ret = data
      break
    case CREATE:
      ret = {data: {id: 0}}
      break
    case DELETE:
      ret = {data}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
