import {
  GET_LIST,
} from 'react-admin'

export async function request(apiUrl, type, params) {
  let url = apiUrl + '/videos/users'
  let options = {}
  switch (type) {
    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case GET_LIST:
      data.data = data.data.map(item => {
        item.thumbnail = convertImagePath(item.thumbnail)
        return item
      })
      if (data) ret = data
      break

    default:
      ret = {data}
      break
  }
  return ret
}

export const convertImagePath = (path) => {
  return `${process.env.REACT_APP_FILE_URL}/${encodeURIComponent(path)}`
}


