import React, {useEffect, useState} from 'react'
import {
  Create,
  ImageInput,
  SimpleForm,
  TextInput,
  ImageField,
  BooleanInput,
  FileInput,
  FileField,
  SelectArrayInput
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import {required} from '../../validators'
import CreateActions from '../../components/actions/CreateActions'
import CreateToolbar from '../../components/toolbars/CreateToolbar'
import {dataProvider} from "../../App"

const VideoCreate = (props) => {
  const [categories, setCategories] = useState([false])
  useEffect(() => {
    async function getCategories() {
      try {
        const {data} = await dataProvider('GET_LIST', 'categories', {
          pagination: {page: 1, perPage: 100},
          sort: {},
          filter: {status: true}
        })
        setCategories(data)
      } catch (e) {
        console.log(e)
      }
    }
    getCategories().then()
  }, [])

  return (
    <Create {...props} actions={<CreateActions/>}>
      <SimpleForm
        toolbar={<CreateToolbar/>}>
        <SelectArrayInput label="카테고리" source="categoryIds" validate={required} multiple={true} choices={
          [
            {id: 2, name: '수면'},
            {id: 3, name: '명상'},
            {id: 4, name: '집중'},
            ...categories.map(category => {
            return {id: category.id, name: `레슨 - ${category.name}`}})
          ]
        } />
        <TextInput source="name" validate={required}/>
        <TextInput source="subName" validate={required}/>
        <RichTextInput source="contents" validate={required}/>
        <ImageInput source="thumbnail" accept="image/*" label="세로용 썸네일" validate={required}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="listThumbnail" accept="image/*" label="목록용 썸네일" validate={required}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="recommendThumbnail" accept="image/*" label="추천용 썸네일" validate={required}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <FileInput
          source="highPath"
          label="동영상(1080p)"
          validate={required}
          accept="video/mp4"
        >
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput
          source="middlePath"
          label="동영상(720p)"
          validate={required}
          accept="video/mp4"
        >
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput
          source="lowPath"
          label="동영상(480p)"
          validate={required}
          accept="video/mp4"
        >
          <FileField source="src" title="title" />
        </FileInput>
        <TextInput source="totalTime" placeholder={"00:00:00"} validate={required}/>
        <BooleanInput source="activeStatus" validate={required}/>
        <BooleanInput source="isSecret" validate={required}/>
        <BooleanInput source="isRecommend" validate={required}/>
        <TextInput source="tags" placeholder={"콤마로 구분(ex: 운동,식단,하체)"} validate={required}/>
        <SelectArrayInput label="연령대" source="birthRangeIds" multiple={true} choices={[
          { id: 1, name: '19세 이하' },
          { id: 2, name: '20 - 24세' },
          { id: 3, name: '25 - 29세' },
          { id: 4, name: '30 - 34세' },
          { id: 5, name: '35 - 39세' },
          { id: 6, name: '40 - 44세' },
          { id: 7, name: '50세 이상' }
        ]} />
        <SelectArrayInput label="관심운동" source="exerciseIds" multiple={true} choices={[
          { id: 1, name: '기초체력증진' },
          { id: 2, name: '근력강화' },
          { id: 3, name: '체형 교정·밸런스' },
          { id: 4, name: '스트레칭' },
          { id: 5, name: '칼로리버닝' },
          { id: 6, name: '혈액순환' },
          { id: 7, name: '힐링' }
        ]} />
        <SelectArrayInput label="관리부위" source="musclePartIds" multiple={true} choices={[
          { id: 1, name: '전신' },
          { id: 2, name: '상체' },
          { id: 3, name: '하체' },
          { id: 4, name: '코어·복부·척추' },
          { id: 5, name: '허리·골반·등' },
          { id: 6, name: '목·팔·어깨' },
          { id: 7, name: '엉덩이·허벅지' }
        ]} />
      </SimpleForm>
    </Create>
  )
}

export default VideoCreate
