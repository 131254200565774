import React from 'react'
import {
  Create, ImageField, ImageInput,
  SimpleForm
} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../components/actions/CreateActions'
import CreateToolbar from '../../components/toolbars/CreateToolbar'

const BannerCreate = (props) => {
  return (
    <Create {...props} actions={<CreateActions/>}>
      <SimpleForm
        toolbar={<CreateToolbar/>}>
        <ImageInput source="image" accept="image/*" label="이미지" validate={required}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <div style={{width: '400px', color: 'gray'}}>
          * 335*556(비율 고정, 이미지 3배율 작업 권장)
        </div>
      </SimpleForm>
    </Create>
  )
}

export default BannerCreate
