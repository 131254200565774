import React, {useState} from 'react'
import {Button as AdminButton} from 'react-admin'
import Button from '@material-ui/core/Button'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import InputLabel from "@material-ui/core/InputLabel"
import TextField from "@material-ui/core/TextField"
import {dataProvider} from "../../App"

const MatchDialog = ({showDialog, setShowDialog, refresh}) => {
  const [title, setTitle] = useState('')
  const [contents, setContents] = useState('')

  const handleSaveButtonClick = async () => {
    try {
      await dataProvider('DIRECT_PUSH', 'pushes', {
        data: {
          title,
          contents
        }
      })
      setShowDialog(false)
      setTitle('')
      setContents('')
      refresh()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Dialog
        open={showDialog}
        aria-label="푸시 즉시 발송"
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            setShowDialog(false)
          }
        }}
      >
        <div style={{padding: '31px 0 0 37px', width: '400px', fontWeight: 'bold', fontSize: '19px', marginBottom: '20px'}}>푸시 발송</div>
        <DialogContent>
          <div style={{height: 'auto', width: '400px', paddingTop: '20px', paddingLeft: '12px', borderTop: '1px solid #eee', borderBottom: '1px solid #eee'}}>
            <InputLabel>제목</InputLabel>
            <TextField
              type="text"
              fullWidth
              value={title}
              InputLabelProps={{
                shrink: false,
              }}
              onChange={(event) => setTitle(event.target.value)}
            />
            <div style={{height: '30px'}}/>
            <InputLabel>내용</InputLabel>
            <TextField
              type="text"
              fullWidth
              value={contents}
              multiline={true}
              InputLabelProps={{
                shrink: false,
              }}
              onChange={(event) => setContents(event.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div
            className="loginButton"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Button
              variant="contained"
              type="button"
              color="primary"
              fullWidth
              onClick={handleSaveButtonClick}
              label="확인"
            >
              확인
            </Button>
          </div>
          <AdminButton label="취소" onClick={() => {
            setShowDialog(false)
            setTitle('')
            setContents('')
          }}>
            <IconCancel />
          </AdminButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default MatchDialog
