import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField
} from 'react-admin'
import {ShowAction} from '../../components/actions'


const CategoryShow = (props) => {
  return (
    <Show {...props} actions={<ShowAction record={props}/>}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <TextField source="sortOrder" sortable={false}/>
        <TextField source="name"/>
      </SimpleShowLayout>
    </Show>
  )
}

export default CategoryShow
