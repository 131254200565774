export const menus = [
  {
    title: '사용자 관리',
    items: ['users', 'usersVideos'],
    names: ['사용자 관리', '북마크 목록'],
    roles: [
      []
    ]
  },
  {
    title: '카테고리 관리',
    items: ['categories'],
    names: ['레슨 카테고리'],
    roles: [
      []
    ]
  },
  {
    title: '콘텐츠 관리',
    items: ['videos'],
    names: ['콘텐츠 관리'],
    roles: [
      []
    ]
  },
  {
    title: '태그 관리',
    items: ['tags'],
    names: ['태그 관리'],
    roles: [
      []
    ]
  },
  {
    title: '푸시 관리',
    items: ['pushes'],
    names: ['푸시 관리'],
    roles: [
      []
    ]
  },
  {
    title: '서비스 관리',
    items: ['notices', 'faqs', 'questions', 'banners'],
    names: ['공지사항 관리', 'FAQ 관리', '1:1 문의', '팝업 관리'],
    roles: [
      []
    ]
  },
  {
    title: '관리자 관리',
    items: ['admins'],
    names: ['관리자 관리'],
    roles: [
      []
    ]
  }
]
