import React from 'react'
import {
  BooleanInput,
  Edit, ImageField, ImageInput,
  SimpleForm
} from 'react-admin'
import {EditAction} from '../../components/actions'
import {EditToolbar} from '../../components/toolbars'
import {required} from "../../validators"

const BannerEdit = (props) => {
  return (
    <Edit {...props} actions={<EditAction/>}>
      <SimpleForm toolbar={<EditToolbar/>}>
        <ImageInput source="image" accept="image/*" label="이미지" validate={required}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <div style={{width: '400px', color: 'gray', marginBottom: '30px'}}>
          * 335*556(비율 고정, 이미지 3배율 작업 권장)
        </div>
        <BooleanInput source="isActive" validate={required}/>
      </SimpleForm>
    </Edit>
  )
}

export default BannerEdit
