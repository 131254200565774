import React from 'react'
import {DateField, Show, SimpleShowLayout, TextField} from 'react-admin'
import {DeleteActions} from '../../components/actions'


const AdminShow = (props) => {
  return (
    <Show {...props} actions={<DeleteActions record={props}/>}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <TextField source="name"/>
        <DateField source="createdAt" locales='ko' showTime/>
      </SimpleShowLayout>
    </Show>
  )
}

export default AdminShow
