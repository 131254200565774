import {withStyles} from '@material-ui/core/styles'
import React, {useState} from 'react'
import {
  TopToolbar,
  CreateButton,
  Datagrid,
  List,
  TextField,
  RichTextField,
  DateField,
  useRefresh,
  Filter, SearchInput
} from 'react-admin'
import DirectDialog from './DirectDialog'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})

const ListFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" placeholder="제목으로 검색" alwaysOn/>
  </Filter>
)

const Actions = ({basePath, handleDialog}) => (
  <TopToolbar>
    <Button
      variant="contained"
      onClick={handleDialog}
      style={{
        width: '110px', backgroundColor: '#1d2f96',
        color: 'white', height: '30px', fontSize: '15px'
      }}
    >
      <span style={{fontSize: '25px', height: '48px'}}>+&nbsp;</span>지금발송
    </Button>
    <CreateButton basePath={basePath}/>
  </TopToolbar>
)

const PushList = withStyles(styles)(({classes, ...props}) => {
  const [showDialog, setShowDialog] = useState(false)
  const refresh = useRefresh()
  const handleDialog = () => {
    setShowDialog(true)
  }

  return (
    <>
      <List {...props}
            filters={<ListFilter/>}
            sort={{ field: 'id', order: 'DESC' }}
            actions={<Actions handleDialog={handleDialog} />}
            perPage={25}
            bulkActionButtons={false}>
        <Datagrid rowClick="show">
          <TextField source="id" sortable={false}/>
          <TextField source="title" sortable={false}/>
          <RichTextField source="contents" sortable={false}/>
          <DateField source="reservedDate" locales='ko' showTime sortable={false}/>
          <TextField source="status" sortable={false}/>
          <DateField source="createdAt" locales='ko' showTime sortable={false}/>
        </Datagrid>
      </List>
      <DirectDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        refresh={refresh}
      />
    </>
  )
})

export default PushList
