import {
  CREATE,
  GET_ONE,
  GET_LIST,
  DELETE,
  UPDATE,
} from 'react-admin'
import {uploadToS3} from './fileHandler'

export async function request(apiUrl, type, params) {
  let url = apiUrl + '/videos'
  let options = {}
  switch (type) {
    case CREATE:
      options.method = 'POST'
      await uploadToS3(params.data, 'thumbnail', 'images', null)
      await uploadToS3(params.data, 'listThumbnail', 'images', null)
      await uploadToS3(params.data, 'recommendThumbnail', 'images', null)
      await uploadToS3(params.data, 'highPath', 'videos', null)
      await uploadToS3(params.data, 'middlePath', 'videos', null)
      await uploadToS3(params.data, 'lowPath', 'videos', null)
      options.data = params.data
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break
    case UPDATE:
      options.method = 'PUT'
      url += `/${params.id}`
      await uploadToS3(params.data, 'thumbnail', 'images', null)
      await uploadToS3(params.data, 'listThumbnail', 'images', null)
      await uploadToS3(params.data, 'recommendThumbnail', 'images', null)
      await uploadToS3(params.data, 'highPath', 'videos', null)
      await uploadToS3(params.data, 'middlePath', 'videos', null)
      await uploadToS3(params.data, 'lowPath', 'videos', null)
      options.data = params.data
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data, status} = res
  switch (type) {
    case GET_LIST:
      data.data = data.data.map(item => {
        item.thumbnail = convertImagePath(item.thumbnail)
        item.contents = `${item.contents.replace(/(<([^>]+)>)/ig,"").substring(0, 20)}...`
        return item
      })
      if (data) ret = data
      break
    case GET_ONE:
      const ranges = [
        { id: 1, name: '19세 이하' },
        { id: 2, name: '20 - 24세' },
        { id: 3, name: '25 - 29세' },
        { id: 4, name: '30 - 34세' },
        { id: 5, name: '35 - 39세' },
        { id: 6, name: '40 - 44세' },
        { id: 7, name: '50세 이상' }
      ]
      const healingCategoryIds = [2, 3, 4]
      if (healingCategoryIds.indexOf(data.categories[0].id) === -1) {
        data.birthRanges = data.birthRangeIds.map(id => {
          return {id, name: ranges[id - 1].name}
        })
        data.exerciseIds = data.exercises.map(item => item.id)
        data.musclePartIds = data.muscleParts.map(item => item.id)
      } else {
        data.birthRanges = []
        data.exerciseIds = []
        data.musclePartIds = []
      }
      data.thumbnailPath = convertImagePath(data.thumbnail)
      data.listThumbnailPath = convertImagePath(data.listThumbnail)
      data.recommendThumbnailPath = convertImagePath(data.recommendThumbnail)
      data.thumbnail = {id: 1, src: convertImagePath(data.thumbnail)}
      data.listThumbnail = {id: 1, src: convertImagePath(data.listThumbnail)}
      data.recommendThumbnail = {id: 1, src: convertImagePath(data.recommendThumbnail)}
      data.highVideo = convertImagePath(data.highPath)
      data.middleVideo = convertImagePath(data.middlePath)
      data.lowVideo = convertImagePath(data.lowPath)
      data.highPath = {id: 1, src: convertImagePath(data.highPath)}
      data.middlePath = {id: 1, src:convertImagePath(data.middlePath)}
      data.lowPath = {id: 1, src: convertImagePath(data.lowPath)}
      data.categoryIds = data.categories.map(item => item.id)
      data.tags = data.tagNames.map(item => item.name).join(',')
      ret = {data}
      break
    case DELETE:
      if(status === 204) ret = {data: {id: params.id}}
      break
    default:
      ret = {data}
      break
  }
  return ret
}

export const convertImagePath = (path) => {
  return `${process.env.REACT_APP_FILE_URL}/${encodeURIComponent(path)}`
}


