import React from 'react'
import {
  Edit, SaveButton, BooleanInput,
  SimpleForm, TextInput, Toolbar,
} from 'react-admin'
import {required} from '../../validators'
import {EditAction} from '../../components/actions'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
  defaultToolbar: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start'
  }
})

const EditToolbar = (props) => {
  const classes = useStyles()
  return (
    <Toolbar {...props} className={classes.defaultToolbar}>
      <SaveButton submitOnEnter />
    </Toolbar>
  )
}

const EditAdmin = (props) => (
  <Edit {...props} actions={<EditAction/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <TextInput disabled source='id'/>
      <TextInput disabled source="nickname" validate={required}/>
      <BooleanInput label="유료구독 여부" source="isPaid"/>
    </SimpleForm>
  </Edit>
)

export default EditAdmin
