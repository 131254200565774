import React from 'react'
import Chip from '@material-ui/core/Chip'

const styles = {
  main: {display: 'flex', flexWrap: 'wrap'},
  chip: {margin: 4},
}

const chipArrayField = ({record, type}) => {
  let types = []
  if (type === 'musclePartIds' && record.musclePartIds) {
    record.musclePartIds.forEach(musclePart => {
      types.push(musclePart.name)
    })
  }
  if (type === 'exerciseIds' && record.exerciseIds) {
    record.exerciseIds.forEach(exercise => {
      if(exercise.id) types.push(exercise.name)
    })
  }
  if (type === 'categories' && record.categories) {
    record.categories.forEach(item => {
      types.push(item.name)
    })
  }
  if (type === 'tagNames' && record.tagNames) {
    record.tagNames.forEach(item => {
      types.push(item.name)
    })
  }
  if (type === 'birthRanges' && record.birthRanges) {
    record.birthRanges.forEach(item => {
      types.push(item.name)
    })
  }
  if (type === 'exercises' && record.exercises) {
    record.exercises.forEach(item => {
      types.push(item.name)
    })
  }
  if (type === 'muscleParts' && record.muscleParts) {
    record.muscleParts.forEach(item => {
      types.push(item.name)
    })
  }
  return (
    <span style={styles.main}>
        {
          types.length > 0 ? types.map((type, i) => (
            <Chip
              key={i}
              style={styles.chip}
              label={type}
            />
          )) : '-'
        }
    </span>
  )
}

chipArrayField.defaultProps = {
  addLabel: true
}
export default chipArrayField
