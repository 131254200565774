import CategoryIcon from '@material-ui/icons/Category'
import CategoryList from './CategoryList'
import CategoryShow from './CategoryShow'
import CategoryEdit from './CategoryEdit'
import CategoryCreate from './CategoryCreate'

const Videos = {
  create: CategoryCreate,
  list: CategoryList,
  show: CategoryShow,
  edit: CategoryEdit,
  icon: CategoryIcon,
}


export default Videos
